import { API, Auth } from "aws-amplify";
import {Checkbox} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import ButtonMaterialUIProgress from "../../CommonUtilities/ButtonMaterialUIProgress";
import Typography from "@material-ui/core/Typography";


export default function StudentDocumentsTable({studentUuid}) {
    const [documents, setDocuments] = useState([]);
    const [loading, setLoading] = useState(true);

    function loadStudentDocumentsApi() {
        return API.get("misServiciosEscolares", `/documents/${studentUuid}`);
    }

    const setStudentDocumentsApi = () => {
        return API.put("misServiciosEscolares", `/documents/${studentUuid}`, {
            body: documents
        });
    };

    const getKardexUrl = async (split) => {
        const currentUser = await Auth.currentAuthenticatedUser();
        const userSub = currentUser?.attributes?.sub;

        const path = `/pdf/kardex/${studentUuid}?split=${split}&userId=${userSub}`;
        return API.get("misServiciosEscolares", path);
    };

    async function onLoad() {
        try {
            const documentsMetadata = await loadStudentDocumentsApi();
            setDocuments(documentsMetadata.documents);
        } catch (e) {
            console.log("Error loading student notes", e.message);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        onLoad();
    }, [studentUuid]);

    const handleCheckboxChange = (event, index) => {
        setDocuments([
            ...documents.slice(0, index),
            {
                ...documents[index],
                delivered: event.target.checked,
            },
            ...documents.slice(index + 1)
        ]);
    };

    const updateDocuments = async () => {
        try {
            setLoading(true);
            await setStudentDocumentsApi();
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    }

    const getKardex = async () => {
        try {
            setLoading(true);
            /**
             * Getting Split from user input. Default is 26
             */
            const split = window.prompt("Please enter a number", "26");
            if (split === null) {
                // User clicked "Cancel", stop execution
                return;
            }
            if (isNaN(Number(split))) {
                // User didn't enter a valid number, stop execution
                console.log("Invalid number entered");
                return;
            }

            /**
             * Getting Kardex URL
             */
            const {data: url} = await getKardexUrl(split);
            console.log(url);
            window.open(url, '_blank');
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <div>
                <Typography variant="h5" component="h2">
                    Imprimir Documentos
                </Typography>
                <ButtonMaterialUIProgress
                    text="Crear Kardex"
                    color='secondary'
                    variant='contained'
                    onClick={getKardex}
                    loading={loading}
                />
                {/*<div>*/}
                {/*    <Link target="_blank" href={`https://prod.api.misserviciosescolares.com/pdf/kardex/${studentUuid}?split=32`}>Kardex</Link>*/}
                {/*</div>*/}
            </div>
            <div style={{marginTop: '25px'}}>
                <Typography variant="h5" component="h2">
                    Registrar Documentos entregados
                </Typography>
                <ButtonMaterialUIProgress
                    text="Guardar Información"
                    color='secondary'
                    variant='contained'
                    onClick={updateDocuments} loading={loading}
                />
                {documents.length > 0 && documents.map((document, index) => {
                    return <div>
                        <>
                            <Checkbox
                                checked={document.delivered}
                                onChange={e => handleCheckboxChange(e, index)}
                                inputProps={{'aria-label': 'primary checkbox'}}
                            />
                        </>
                        <>{document.name}</>
                    </div>;
                })}
            </div>
        </div>
    );
};
